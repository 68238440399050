<template>
  <div class="text-white px-4 pt-16 md:pt-32">
    <h1 class="py-2">Imprint</h1>
    <p>
      Momentum.info is a production of the Deutsche Gesellschaft für
      Internationale Zusammenarbeit (GIZ GmbH) on behalf of the Federal Ministry
      for Economic Cooperation and Development and in cooperation with the
      Working Group of the Commissioner of the Federal Government for Drug and
      Addiction Policy.
    </p>
    <h3 class="py-6">Published by</h3>
    <p>Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) GmbH</p>
    <div class="py-4">
      <address class="not-italic">
        Friedrich-Ebert-Allee 32 + 36<br />
        53113 Bonn, Germany
      </address>
      Phone: <a href="tel:+4922844600">+49 228 4460-0</a><br />
      Fax: <a href="tel:+4922844601766">+49 228 4460-1766</a>
    </div>
    <div class="py-4">
      <address class="not-italic">
        Dag-Hammarskjöld-Weg 1–5<br />
        65760 Eschborn, Germany
      </address>
      Phone: <a href="tel:+496196790">+49 6196 79-0</a><br />
      Fax: <a href="tel:+496196791115"> +49 6196 79-1115</a>
    </div>
    <div class="py-4">
      <p>
        <a href="mailto:info@giz.de">E-Mail: info@giz.de</a><br />
        Homepage: <a href="www.giz.de" target="_blank">www.giz.de</a>
      </p>
    </div>
    <div class="py-4">
      <p>
        Registered at<br />
        Local court (Amtsgericht) Bonn, Germany: HRB 18384<br />
        Local court (Amtsgericht) Frankfurt am Main, Germany: HRB 12394
      </p>
    </div>
    <div class="py-4">
      <p>Vat (no.) DE 113891176</p>
    </div>
    <div class="py-4">
      <p><strong>Chairperson of the Supervisory Board </strong></p>
      <p>Martin Jäger</p>
    </div>
    <div class="py-4">
      <p><strong>Management Board </strong></p>
      <p>
        Tanja Gönner (Chair of the Management Board)<br />
        Ingrid-Gabriela Hoven<br />
        Thorsten Schäfer-Gümbel
      </p>
    </div>
    <div class="py-4">
      <p><strong>Coordination and editing</strong></p>
      <p>Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) GmbH</p>
    </div>
    <div class="py-4">
      <p><strong>Design and Technical Implementation </strong></p>
      <p>Edeos</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Imprint",
  methods: {
    updateBodyBackground(bgColorClass) {
      this.removeBackgroundClassFromBody();
      document.body.classList.add(bgColorClass);
    },
    removeBackgroundClassFromBody() {
      document.body.classList.forEach(function (val, index, listObj) {
        if (val.startsWith("bg-")) {
          listObj.remove(val);
        }
      });
    },
  },
  created() {
    this.updateBodyBackground("bg-orange");
  },
};
</script>