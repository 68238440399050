const creditsStore = {
  data: {
    intro: [
      {
        frame: "0.3",
        officialSourceData:
          "UNODC Word Drug Report 2021, Executive Summary Policy Implications, June 2021, P. 53",
      },
      {
        frame: "0.3",
        officialSourceData:
          "UNODC Cocaine Insights1, The illicit trade of cocaine from Latin America to Europe, 2021, P. 13.",
      },
      {
        frame: "0.4",
        officialSourceData:
          "UNODC Cocaine Insights1, The illicit trade of cocaine from Latin America to Europe, 2021, P. 9.",
      },
      {
        frame: "0.5",
        officialSourceData:
          "Council of the European Union, EU Drugs Strategy 2021 - 2025",
      },
    ],
    story1: [
      {
        frame: "1.1.12",
        officialSourceData:
          "Mae Fah Luang Foundation - Alternative Development",
      },
      {
        frame: "1.1.15",
        officialSourceData:
          "GPDPD, Alternative Development, ending the vicious cycle of the illicit drug economy, 2021, https://www.gpdpd.org/drogenpolitik/alternative-entwicklung ",
      },
      {
        frame: "1.1.16",
        officialSourceData:
          "LSE Research, After the Drug Wars, 2021, https://www.youtube.com/watch?v=Qx_GlcMwaec ",
      },
      {
        frame: "1.2.7",
        officialSourceData:
          "GPDPD, Drugs and the Environment, Untold Damage, 2021, https://www.gpdpd.org/drogenpolitik/drogen-und-umwelt ",
      },
      {
        frame: "1.2.9",
        officialSourceData:
          "GPDPD, Alternative Development, Protecting Human Rights for sustainable Change, 2021,  https://www.gpdpd.org/drogenpolitik/drogenpolitik-und-menschenrechte ",
      },
      {
        frame: "1.2.12",
        officialSourceData:
          "GIZ, Turning vulnerability into strength: Women in drug crop cultivation areas, 2020, https://www.youtube.com/watch?v=BrERZjvxI4s https://www.youtube.com/watch?v=uRqGvoYWCUw ",
      },
      {
        frame: "1.2.15",
        officialSourceData:
          "LSE Research, After the Drug Wars, 2021, https://www.youtube.com/watch?v=Qx_GlcMwaec ",
      },
    ],
    story2: [
      {
        frame: "2.1.3",
        officialSourceData:
          "Global Initiative against transnational organized crime, the Cocaine Pipeline to Europe, February 2021, P. 3",
      },
      {
        frame: "2.1.4",
        officialSourceData:
          "Global Initiative against transnational organized crime, West Africa’s Cocaine Corridor. Building a subregional response, April 2021, P. 2",
      },
      {
        frame: "2.1.5",
        officialSourceData:
          "Global Initiative against transnational organized crime, the Cocaine Pipeline to Europe, February 2021, P. 4",
      },
      {
        frame: "2.2.5",
        officialSourceData: "UNODC, World Drug Report 2021, June 2021, P. 11",
      },
      {
        frame: "2.3.4",
        officialSourceData:
          "Harm Reduction International. The Global State of Harm Reduction 2020, 2020, P. 163",
      },
    ],
    story3: [
      {
        frame: "3.1.3",
        officialSourceData:
          "Harm Reduction International. The Global State of Harm Reduction 2020, 2020, P. 19",
      },
      {
        frame: "3.1.4",
        officialSourceData:
          "UNODC Word Drug Report 2021, Executive Summary Policy Implications, June 2021, P. 61 ",
      },
      {
        frame: "3.1.4",
        officialSourceData:
          "UNODC Word Drug Report 2021, Executive Summary Policy Implications, June 2021, P. 39 ",
      },
      {
        frame: "3.2.8",
        officialSourceData:
          "Foto wurde als Grundlage für eine Illustration verwendet. Keiner Quellennachweis erforderlich.",
      },
      {
        frame: "3.2.11",
        officialSourceData:
          "Harm Reduction International. The Global State of Harm Reduction 2020, 2020, P. 17",
      },
      {
        frame: "3.2.14",
        officialSourceData:
          "Foto wurde als Grundlage für eine Illustration verwendet. Keiner Quellennachweis erforderlich.",
      },
    ],
  },
};

export default creditsStore;
