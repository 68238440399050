<template>
  <!-- <div class="grid grid-cols-2 gap-4 pb-2">
    <div>{{ sourceData }}</div>
    <div>{{ officialSourceData }}</div>
  </div> -->
  <li class="pb-2">{{ officialSourceData }}</li>
</template>

<script>
export default {
  name: "CreditItem",
  props: {
    frame: {
      type: String,
    },
    source: {
      type: String,
    },
    officialSource: {
      type: String,
    },
  },
  data() {
    return {
      sourceData: this.source,
      officialSourceData: this.officialSource,
    };
  },
  computed: {
    // bgColor: function () {
    //   return this.background != undefined ? this.background[0] : "";
    // },
  },
  methods: {
    // collectClasses() {
    //   this.classesCombined.push(
    //     this.bgColor,
    //     this.bgColorBefore,
    //     this.bgColorAfter
    //   );
    // },
  },
  created() {
    // this.collectClasses();
  },
};
</script>