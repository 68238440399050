<template>
  <div class="text-white px-4 pt-16 md:pt-32">
    <h1 class="py-2">Credits</h1>
    <h2 class="py-2">Intro</h2>
    <div
      v-for="(introCreditItem, index) in creditsData.data.intro"
      :key="index"
    >
      <credit-item
        :source="introCreditItem.frame"
        :officialSource="introCreditItem.officialSourceData"
      ></credit-item>
    </div>

    <hr class="my-2" />

    <h2 class="py-4">Story Ana</h2>
    <div
      v-for="(introStoryAnaItem, index) in creditsData.data.story1"
      :key="index"
    >
      <credit-item
        :source="introStoryAnaItem.frame"
        :officialSource="introStoryAnaItem.officialSourceData"
      ></credit-item>
    </div>

    <hr class="my-2" />

    <h2 class="py-2">Story Kofi</h2>
    <div
      v-for="(introStoryKofiItem, index) in creditsData.data.story2"
      :key="index"
    >
      <credit-item
        :source="introStoryKofiItem.frame"
        :officialSource="introStoryKofiItem.officialSourceData"
      ></credit-item>
    </div>

    <hr class="my-2" />

    <h2 class="py-2">Story Mila</h2>
    <div
      v-for="(introStoryMilaItem, index) in creditsData.data.story3"
      :key="index"
    >
      <credit-item
        :source="introStoryMilaItem.frame"
        :officialSource="introStoryMilaItem.officialSourceData"
      ></credit-item>
    </div>
  </div>
</template>

<script>
import CreditItem from "./CreditItem.vue";
import creditsStore from "./creditsStore.js";
export default {
  name: "Credits",
  components: {
    CreditItem,
  },
  data() {
    return { creditsData: creditsStore };
  },
  methods: {
    updateBodyBackground(bgColorClass) {
      this.removeBackgroundClassFromBody();
      document.body.classList.add(bgColorClass);
    },
    removeBackgroundClassFromBody() {
      document.body.classList.forEach(function (val, index, listObj) {
        if (val.startsWith("bg-")) {
          listObj.remove(val);
        }
      });
    },
  },
  created() {
    this.updateBodyBackground("bg-orange");
  },
};
</script>